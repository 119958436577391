<template>
  <div id="vipMall">
    <!-- <div class="topVipCard">
      <div class="cardTop">
        <p class="vipState">您尚未开通会员!</p>
        <p class="vipTxt">开通会员即可领取15张优惠券</p>
        <p class="vipTxt">您本月还有N项权益未领取</p>
        <p class="vipTxt">预计本月优惠 <span class="num">50.00</span> 元</p>
        <p class="vipTxt">
          本月已省 <span class="num">50.00</span> 元
          <span class="detailLink">详情</span>
        </p>
      </div>
      <div class="cardBottom">
        <span class="myCouponLink">优惠券</span>
        <span class="myOrderLink" @click="toLink('/h/scm/order/orderList')"
          >订单管理 ></span
        >
      </div>
      <span class="openBtn">立即开通</span>
    </div> -->
    <div class="toSaleLink" @click="toLink('/h/scm/mall/hotSale')">
      <img src="@/assets/mall/salelinkbg.png" alt="" class="linkImg" />
    </div>
    <div class="discountPart">
      <div class="title">会员折扣购</div>
      <van-list
        class="discountGoodsList"
        v-model="listIsLoading"
        :finished="loadFinised"
        @load="getDiscountGoodsList"
      >
        <!-- 空数据占位start -->
        <template v-if="!isListInit">
          <div
            class="aGoods loading"
            v-for="n in 3"
            :key="'aDiscountGoods' + n"
          >
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <span class="saleSign gapHidden">专享折后价</span>
            <p class="goodsPrize gapLoad">--</p>
          </div>
        </template>
        <!-- 空数据占位end -->
        <div
          class="aGoods"
          v-for="item in goodsList"
          :key="item.spuNo"
          @click="toGoodsDetail(item)"
        >
          <div
            class="goodsImg"
            :class="{
              noImg: !item.spuSnapshotGallery || !item.spuSnapshotGallery.imgUrl
            }"
          >
            <img
              :alt="item.name"
              v-if="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
              :src="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
            />
          </div>
          <p class="goodsName">{{ item.name }}</p>
          <span class="saleSign">专享折后价</span>
          <p class="goodsPrize">
            <span class="new">￥{{ item.currentPrice }}</span>
            <!-- <span class="old">￥{{ item.originalPrice }}</span> -->
          </p>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "vipMall",
  data() {
    return {
      sceneNo:
        this.$route.query.scence ||
        this.$store.state.sceneNo ||
        sessionStorage.getItem("sceneNo") ||
        "", //  场景值
      curPage: 0, //  当前列表请求页数
      pageSize: 20, //  一次请求加载个数
      isListInit: false, //  是否初始化
      listIsLoading: false, //  列表是否正在加载
      loadFinised: true, //  列表全部加载完成标识(初始设置为true是手动加载列表，防止组件会自动加载列表)
      goodsList: [] //  热门商品列表
    };
  },
  created() {
    if (!!this.sceneNo) this.$store.commit("UPDATESCENCENO", this.sceneNo);
    this.getDiscountGoodsList();
  },
  methods: {
    // 获取折扣商品列表
    getDiscountGoodsList() {
      this.curPage++;
      this.global.getGoodsList(
        this.sceneNo,
        this.curPage,
        this.pageSize,
        (res) => {
          this.isListInit = true;
          this.listIsLoading = false;
          this.loadFinised = !res || res.length < this.pageSize; //  如果接口返回的数据小于分页数说明没有下一页了（接口没有返回总数 通过此方法来判断是否可以加载下一页）
          if (res.length) {
            this.goodsList = this.goodsList.concat(res);
          }
        }
      );
    },
    //  跳转商品
    toGoodsDetail(item) {
      this.$router.push({
        path: "/h/scm/mall/goodsDetail",
        query: {
          sceneNo: this.sceneNo,
          spuId: item.spuId
        }
      });
    },
    //  跳转链接
    toLink(link) {
      this.$router.push({
        path: link
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin textGradient($color1, $color2) {
  background-image: linear-gradient(to bottom, $color1, $color2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@supports (-webkit-background-clip: text) and
  (-webkit-text-fill-color: transparent) {
  .cardTop .vipState,
  .cardTop .vipTxt .num {
    @include textGradient(#6f2602, #aa785f);
  }
}
.topVipCard {
  margin: 30px;
  height: 310px;
  border-radius: 24px;
  overflow: hidden;
  background: url(~@/assets/mall/vipcardbg.png) center / 100% 100%,
    linear-gradient(#ffefdb, #fce7cb);
  position: relative;
  .openBtn {
    box-sizing: border-box;
    width: 270px;
    height: 72px;
    padding-right: 55px;
    font-size: 34px;
    line-height: 72px;
    text-align: right;
    color: #da6338;
    text-shadow: 0 1px 4px #ffe8a8;
    display: block;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
    background: url(~@/assets/mall/vipopenbtn.png) center / 100% 100%;
  }
  .cardTop {
    padding: 18px 50px 0;
    color: #853711;
    font-size: 30px;
    .vipState {
      line-height: 55px;
      font-weight: bold;
    }
    .vipTxt {
      line-height: 48px;
      .num {
        font-weight: bold;
      }
      .detailLink {
        display: inline-block;
        font-size: 26px;
        color: #d0ad95;
        text-decoration: underline;
        margin-left: 25px;
        &::after {
          content: "";
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url(~@/assets/icons/detailLink.png) center / 22px 22px
            no-repeat;
          vertical-align: text-top;
          margin-left: 5px;
        }
      }
    }
  }
  .cardBottom {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 1;
    .myCouponLink {
      font-size: 30px;
      color: #853711;
      &::before {
        content: "";
        width: 50px;
        height: 38px;
        display: inline-block;
        background: url(~@/assets/icons/coupon.png) left center / 38px auto
          no-repeat;
        vertical-align: text-bottom;
      }
    }
    .myOrderLink {
      font-size: 24px;
      color: #853711;
    }
  }
}
.toSaleLink {
  margin: 30px auto;
  width: 690px;
  height: 200px;
  .linkImg {
    display: block;
    width: 100%;
  }
}
.discountPart {
  background: #fff;
  margin: 0 30px 30px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  overflow: hidden;
  .title {
    height: 118px;
    line-height: 118px;
    text-align: center;
    color: #6f4415;
    font-weight: bold;
    font-size: 34px;
    background: url(~@/assets/mall/viptitlebg.png) center top / 366px auto
        no-repeat,
      linear-gradient(#ffe5c4, #fff);
  }
  .discountGoodsList {
    padding: 16px 0 10px 25px;
    font-size: 0;
    .aGoods {
      display: inline-block;
      width: 200px;
      margin-right: 20px;
      margin-bottom: 30px;
      overflow: hidden;
      vertical-align: top;
      .goodsImg {
        height: 200px;
        border-radius: 12px;
        margin-bottom: 18px;
        overflow: hidden;
      }
      .goodsName {
        height: 50px;
        line-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #853711;
        font-size: 30px;
      }
      .goodsPrize {
        line-height: 30px;
        min-height: 30px;
        margin-top: 6px;
        .new {
          font-size: 26px;
          color: #ea3d39;
        }
        .old {
          font-size: 20px;
          color: #999;
          text-decoration: line-through;
        }
      }
    }
  }
}
</style>